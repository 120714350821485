const checkAllHandler = (form) => {
  const checkAllBox = form.querySelector(".check-all");
  const checkboxes = form.querySelectorAll(".check");

  checkAllBox.addEventListener("change", () => {
    checkboxes.forEach((checkbox) => {
      checkbox.checked = checkAllBox.checked;
    });
  });
};

const handleForms = (formSelector) => {
  const forms = document.querySelectorAll(formSelector);
  forms.forEach((form) => checkAllHandler(form));
};

handleForms(".tw-form");
handleForms(".new-form");


// ------------------ redirect to 'Thanks Page' ------------------

const redirectToThanks = (event) => {
  if (event.detail.contactFormId == "591") {
    location = document.location.origin + "/dziekujemy/";
  } else if (event.detail.contactFormId == "1080") {
    location = document.location.origin + "/dziekujemy-doradca/";
  } else if (event.detail.contactFormId == "1119") {
    location = document.location.origin + "/dziekujemy-sidebar/";
  } else if (event.detail.contactFormId == "836") {
    location = document.location.origin + "/dziekujemy-slider/";
  } else if (event.detail.contactFormId == "27612") {
    location = document.location.origin + "/dziekujemy-slider/";
  } else if (event.detail.contactFormId == "836") {
    location = document.location.origin + "/dziekujemy-zdolnosc/";
  } else {
    location = document.location.origin + "/dziekujemy/?no-detail";
  }
};

// ------------------ custom form event -------------------
const formEvent = (event) => {
  const inputs = event.detail.inputs;
  const telObj = inputs?.find((o) => o.name.match(/tel/));
  const emailObj = inputs?.find((o) => o.name.match(/mail/));
  let tel = telObj ? telObj.value : "no tel";
  let email = emailObj ? emailObj.value : "no email";
  let user_data = {
    tel: "",
    email: "",
  };
  user_data.tel = tel;
  user_data.email = email;

  window.dataLayer.push({
    event: "custom_form",
    user_data,
  });

};

document.addEventListener(
  "wpcf7mailsent",
  (e) => {
    formEvent(e);
    redirectToThanks(e);
  },
  false
);

// ------------------ custom form event --------------




const handleMegaMenu = (menuClass) => {
  const menus = document.querySelectorAll(menuClass);
  menus.forEach((menu) => {
    const megaMenus = menu.querySelectorAll(".has-mega-menu");
    megaMenus.forEach((mega) => {
      const link = mega.querySelector("a");
      // console.log(`link: ${link}`);
      mega.addEventListener("click", (e) => {
        // e.preventDefault();
        mega.classList.toggle("open");
      });
      // window.addEventListener("resize", () => {
      //   if (open) {
      //     open = false;
      //   }
      // });
    });
  });
};

handleMegaMenu(".main-menu");

// const openMenu = (menuClass) => {
//   const menu = document.querySelector(menuClass);
//   const btn = menu.querySelector('#open-menu');
//   const openClass = 'menu-open';
//   let open = false;
//   const handleOpen = (open) => {
//     console.log(open);
//     if(open){
//       document.body.classList.add(openClass);
//     } else {
//       document.body.classList.remove(openClass);
//     }
//   }

//   btn.addEventListener('click', () => {
//     open = !open;
//     handleOpen(open);
//   })
//   window.addEventListener('resize',() => {
//     if(open){
//       open = false;
//       handleOpen(open);
//     }
//   })
// }

// openMenu('.js-main-menu-container');
